import * as React from "react"
import "reset-css"
import { Layout } from "../components/layout"
import { colors, GlobalCss, mediaQuery } from "../theme"
import Seo from "gatsby-plugin-wpgraphql-seo"
import { graphql } from "gatsby"
import { Feature } from "../components/feature/feature"
import styled from "styled-components"
import { ReactComponent as PhoneSvg } from "../images/phone-icon.svg"
import { ReactComponent as EmailSvg } from "../images/email-icon.svg"
import { ReactComponent as MapSvg } from "../images/map.svg"
import { Container } from "../theme"

const ContactsContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  margin: 80px 0 40px;

  ${mediaQuery("sm")} {
    grid-template-columns: 1fr;
    margin-bottom: 0;
  }
`
const IconsContainer = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-decoration: none;

  &:hover {
    span {
      color: ${colors.blue};
    }
  }

  span {
    font-family: DM Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 45px;
    line-height: 60px;
    letter-spacing: -1.584px;
    color: #ef5da8;
    transition: color 0.25s ease-in-out;
  }

  ${mediaQuery("lg")} {
    span {
      font-size: 30px;
    }
  }

  ${mediaQuery("md")} {
    span {
      font-size: 25px;
    }
  }

  ${mediaQuery("md")} {
    span {
      font-size: 20px;
    }
  }
`
const ContactsPage = ({ data: { wpPage } }) => {
  return (
    <>
      <Seo post={wpPage} />
      <GlobalCss />
      <Layout>
        <Feature
          heading="Kontaktai"
          image={wpPage.ACF_feature.image.localFile}
          paragraph={wpPage.ACF_feature.text}
        />
        <Container>
          <ContactsContainer>
            <IconsContainer href={`tel:${wpPage.ACF_contacts.phone}`}>
              <PhoneSvg />
              <span>{wpPage.ACF_contacts.phone}</span>
              <span style={{ display: "block" }}>+37065267000</span>
            </IconsContainer>

            <IconsContainer href={`mailto:${wpPage.ACF_contacts.email}`}>
              <EmailSvg />
              <span>{wpPage.ACF_contacts.email}</span>
            </IconsContainer>
          </ContactsContainer>
          <IconsContainer href={`https://goo.gl/maps/oXvvGotReyCmnBB3A`}>
            <MapSvg />
            <span>Konstitucijos pr. 23, Vilnius</span>
          </IconsContainer>
        </Container>
      </Layout>
    </>
  )
}

export const query = graphql`
  query {
    wpPage(slug: { eq: "kontaktai" }) {
      seo {
        title
        metaDesc
        focuskw
        metaKeywords
        metaRobotsNoindex
        metaRobotsNofollow
        opengraphTitle
        opengraphDescription
        opengraphImage {
          altText
          sourceUrl
          srcSet
        }
        twitterTitle
        twitterDescription
        twitterImage {
          altText
          sourceUrl
          srcSet
        }
        canonical
        cornerstone
        schema {
          articleType
          pageType
          raw
        }
      }
      ACF_feature {
        text
        image {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      ACF_contacts {
        email
        phone
      }
    }
  }
`

export default ContactsPage
